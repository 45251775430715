<template>
    <div>
        <div class="bg">
            <img src="../assets/imgs/uploads/events.jpg" alt="" style="width: 100%;">
            <div class="tit">
                <div style="font-size: 36px;font-weight: bold;display: flex;justify-content: center;margin-bottom: 10px;">
                    News and Events</div>
                <div style="display: flex;justify-content: center;">
                    Welcome to GiGwi News. Here you will find the lastest news and festures from us.
                </div>
                <div style="display: flex;justify-content: center;">
                    Learn more about our events, innovative products, global exhibitions and partnership.
                </div>
            </div>
        </div>
        <div class="center">
            <div style="margin-top: 30px;">
                <div class="tips" v-for="item in list" :key="item.id">
                    <div>
                        <img v-lazy="item.newsImg" alt="" style="width: 636px;height: 100%;">  
                    </div>
                    <div style="margin-left: 30px;">
                        <div style="font-size: 36px;font-weight: bold;color: #000;margin-bottom: 20px;">
                            {{ item.title }}
                        </div>
                        <div style="font-weight: bold;color: #CA141D;margin-bottom: 20px;">
                            {{ item.words }} · {{ item.month }} {{ item.year }}
                        </div>
                        <div style="color: #545454;min-height: 120px;margin-bottom: 38px;">
                        {{ item.excerpt }}</div>
                        <button class="btn" @click="detail(item.id)">Read more</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
        }
    },
    mounted() {
        this.getlist()
    },
    methods: {
        getlist() {
            this.$axios
                .post("/api/mall/news/list")
                .then(res => {
                    if (res.data.code === 200) {
                        this.list = res.data.data
                    } else {
                        this.notifyError(res.data.message);
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        detail(id) {
            this.$router.push({
                path: '/eventscenter',
                query: {
                    id: id
                }
            })
        },
    }
}
</script>

<style scoped>
.center {
    width: 1280px;
    margin: 0 auto;
}

.bg {
    position: relative;
    /* background-image: url('../assets/imgs/uploads/Rectangle 62.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; */
}

.tit {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    color: #FFF;
}

.tips {
    display: flex;
    height: 481px;
    padding: 40px 0;
    border-bottom: 1px solid #D9D9D9;
}
.btn{
    width: 296px;
    height: 47px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;
    background-color: #CA141D;
    color: #FFF;
    border: none;
}
</style>